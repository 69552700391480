.footerContainer {
    width: 100%;
    position: absolute;
    background-color: #4744EB;
    cursor:pointer;
}

.footerLogo {
    margin-top: 10px;
    height: 17px;
    padding: 8px;
    cursor:pointer;
}

.alignText {
    text-align: center;
}

.copyWrite {
    margin-top: 0;
    color: white;
    font-size: 12px;
}