.formButton {
    background-color: white;
    border: none;
    border-radius: 5px;
    color: black;
    padding: 8px 22px;
    margin: 10px auto 0 auto;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    cursor:pointer;
    &:hover {
        background-color: #0f1049;
        color: white;
    }
    &:disabled {
        opacity: .5;
        background-color: white;
        color: black;
    }
}

.form-input {
    margin-bottom: 5px;
    width: 150px;
    padding: 5px;
    outline: none;
    font-weight: 300;
}

