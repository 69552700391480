.quoteContainer {
    // background-color: rgb(238, 238, 238);
    border-radius: 5px;
    // width: 50%;
    max-width: 1200px;
    padding: 40px 10px;
    margin: 20px 40px 0 40px;
    // animation: 1s ease-out 0s 1 slideDown;
}

@keyframes slideDown {
        0% {
            transform: translateY(-10px);
        }
        100% {
            transform: translateY(0);
        }
    }

.homeButton {
    background-color: #5072F2;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 8px 32px;
    margin: 10px auto 0 auto;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor:pointer;
    &:hover {
        background-color: black;
    }
}

.compliance-header {
    color: #5072F2;
    margin-top: 0px;
    margin-bottom: 5px;
}

.home-header {
    margin-top: 0px;
    color: #4744EB;
}

.home-header-text {
    line-height: 1.2;
    color: #4b4b4b;
    font-size: 20px;
}

.noMargin {
    margin: 0 -30px 0
}

.complianceTypes {
    color: #4b4b4b;
    display: flex;
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 15px;
}

.viewPeople {
    width: 100%;
    transform: translate(15px, -10px);
}

.headingContainer {
    display: flex;
    animation: 2s ease-out 0s 1 slideDown;
}

.imageContainer {
    width: 45%;
    background-color: #5072F2;
}

.homeHeaderContainer {
    position: relative;
    padding-left: 50px;
    width: 40%;
    //transform: translateY(50%);
}

.checkIcon {
    width: 15px;
    transform: translateY(2px);
    margin-right: 5px ;
}

.compliance-container {
    display: flex;
    margin: 40px 0;
}

.shield-container {
    width: 50%;
    background-color: #f4f3f3;
    text-align: center;
    padding: 20px 0;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.shieldIcon{
    max-height: 200px;
    transform: translateX(15px);
}

.compliance-text-container{
    width: 55%;
    position: relative;
}

.headerContent {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.content-center-positioned {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}

.waitingListContainer{
    text-align: center;
    margin: 20px auto 0 auto;
    width: 50%;
    color: #4b4b4b;
}

.no-margin {
    margin: 0;
}

@media only screen and (max-width: 890px) {
    .headingContainer {
      flex-direction: column;
    }
    .imageContainer{
        width: 100%;
    }
    .headerContent{
        margin-top: 20px;
        position: relative;
        top: 0;
        transform: translateY(0);
    }
    .homeHeaderContainer {
        width: 100%;
        padding-left: 0;
    }
  }

  .mc__title {
      margin-top: 0px;
      color: white;
  }

  .mc__form {
      text-align: center;
  }

  .mc__field-container {
      margin-bottom: 10px;
  }

  @media only screen and (max-width: 700px) {
    .compliance-container {
      margin: 20px 0;
      flex-direction: column;
    }
    .compliance-text-container{
        width: 100%;
    }
    .content-center-positioned{
        position: initial;
        transform: none;
        margin: auto;
        width: 80%;
    }
    .shield-container{
        margin: 20px auto 0 auto;
        width: 80%;
    }
  }